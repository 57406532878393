.error-url-container {
  width: 90%;
  margin-top: 3vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-evenly;
  color: #2D2E2F;
  font-family: Helvetica, Arial, sans-serif;
}

.error-url-text-container {
  margin-top: 5vw;
}

.error-url-text-container div:first-child,
.error-url-text-container div:nth-child(2) {
  font-size: 1.90vw;
  font-weight: 900;
  letter-spacing: 0.025em;
  text-shadow: 1px 0 #2D2E2F;
}

.error-url-text-container div:first-child {
  margin-bottom: 0.2em;
}

.new-search-container {
  margin-top: 1.25em;
  font-weight: 300;
  font-size: 1.45vw;
  text-shadow: 1px 0 #2D2E2F;
}

.new-search-bold {
  font-weight: 900;
  text-decoration: none;
  text-shadow: 1px 0 #027a97;
  color: #027a97;
}

.new-search-bold:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

.error-url-img-container {
  text-align: center;
}

.error-url-img-container img {
  width: 45vw;
}