#user-pic {
  position: absolute;
  top: 1.7vw;
  right: 6vw;
  width: 2vw;
  height: 2vw;
  cursor: pointer;
  opacity: 0.7;
  text-align: center;
}

#user-pic div {
  position: relative;
}

#user-pic:hover, #user-pic:active {
  opacity: 1;
}

#user-profile {
  position: absolute;
  top: 5em;
  right: 3.8em;
  background-color: rgba(102, 102, 102, 0.7);
  line-height: 2em;
  border-radius: 4px;
  padding: 1vh 1vw;
  width: fit-content;
  font-size: 0.8vw;
  backdrop-filter: blur(10px);
}

.non-hp-profile li:first-child {
  color: white !important;
}

.about-me-btn,
.logout-btn {
  font-size: 0.8vw;
  font-weight: 600;
  width: 6.2vw;
  line-height: 1.5em;
  border-radius: 4px;
  border: 1px solid #666;
  opacity: 0.7;
  cursor: pointer;
  color: #2D2E2F;
}

.about-me-btn:hover,
.logout-btn:hover {
  opacity: 1;
}

.about-me-btn,
.logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-me-btn-text,
.logout-btn-text {
  margin-left: 0.5em;
}

.logout-btn {
  margin-top: 0.5em;
}

.fa-user {
  font-size: 1.5vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}