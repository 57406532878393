.restaurant-sp-container {
  font-family: Helvetica, sans-serif;
  margin-top: 3vw;
  position: relative;
}

.main-bg-scrollable-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0;
  height: 30vw;
}

.main-bg-scrollable-container::-webkit-scrollbar {
  display: none;
}

.bg {
  width: 40vw;
  height: inherit;
  display: inline-block;
  flex: 0 0 auto;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 75%,
   rgb(0, 0, 0, 0.25), rgba(0, 0, 0, 0.55));
}

.bg-img {
  width: inherit;
  height: inherit;
  filter: brightness(0.7);
  object-fit: cover;
}

.unscrollable-info {
  position: absolute;
  z-index: 8;
  top: 17vw;
  left: 6vw;
  border-radius: 4px;
}

.sp-title {
  color: white;
  font-weight: bold;
  font-size: 5vw;
  margin-bottom: 0.5vw;
}

.sp-avg-rating,
.sp-avg-rating-updated {
  font-size: 2vw;
  width: 20vw;
  background-color: rgba(226, 226, 230, 0.5);
  border-radius: 4px;
}

.sp-avg-rating-updated {
  background-color: transparent;
  color: white;
  width: 100%;
}

.sp-category-container {
  margin-top: 0.5vw;
  color: white;
  font-size: 1.5vw;
}

.sp-rest-claimed, 
.sp-rest-notclaimed, 
.sp-price-range, 
.sp-category {
  color: inherit;
  font-size: inherit;
}

.sp-rest-claimed {
  color: #58b4ff;
  margin-right: 1.3vw;
}

.sp-rest-claimed span{
  vertical-align: middle;
}

.sp-rest-notclaimed span {
  vertical-align: middle;
}

.sp-category {
  margin-right: 1vw;
}

.bullet-pt {
  font-size: 1vw !important;
  vertical-align:middle;
  margin: 0px 0.2em;
}

.sp-rest-current-status-container {
  font-size: 1.5vw;
  padding-left: 0.1em;
  margin-top: 0.5vw;
  text-align: left;
}

.curr-rest-hr {
  margin-left: 1vw;
}

.curr-open-status {
  color: #04c585;
}

.curr-closed-status {
  color: #ff8b87;
}

.sp-rest-current-status-container .curr-rest-hr {
  color: white;
}

.sp-map-container {
  width: 23vw;
  height: 12vw;
}

.location-hours-container {
  top: 35vw;
  position: absolute;
  left: 6vw;
  width: 80vw;
  color: #2D2E2F;
}

.location-hours-container span {
  display: inline-block;
  color: inherit;
}

.loc-hr-title {
  font-size: 1.6vw;
  font-weight: bold;
  margin-bottom: 2vh;
  color: inherit;
}

.loc-hr-title span {
  color: inherit;
}

.sp-loc-container {
  color: inherit;
}

.sp-restaurant-addr {
  color: rgba(2, 122, 151, 1);
  font-weight: bold;
  margin-top: 1vw;
  font-size: 1.2vw;
}

.sp-restaurant-loc-container {
  margin-top: 1vw;
}

.sp-restaurant-loc {
  margin-right: 0.5vw;
  font-weight: bold;
  font-size: 1.2vw;
  color: #2D2E2F;
}

.sp-restaurant-hrs {
  width: fit-content;
  font-size: 1.3vw;
  margin-left: 2vw;
  position: absolute;
  top: 0;
}

.sp-restaurant-hrs div {
  margin: 1vw 0px;
}

.day, .open-hrs {
  display: inline-block;
  font-weight: bold;
}

.day div {
  margin-right: 2vw;
}

.rest-curr-status {
  margin-left: 1vw;
}

.rest-open-status {
  color: #008055 !important;
}

.rest-closed-status {
  color: rgba(224, 7, 7, 1) !important;
}

.sp-restaurant-info-box {
  border: 1px solid rgba(202, 201, 202, 1);
  position: absolute;
  right: 6vw;
  width: fit-content;
  max-width: 25vw;
  top: 37vw;
  padding: 0.5vw;
  border-radius: 4px;
  font-size: 1.2vw;
}

.sp-line-divider {
  border-top: 1px solid rgba(202, 201, 202, 1);
}

.sp-restaurant-phone {
  font-size: 1.3vw;
}

.rest-url {
  color: #027A97;
  font-weight: 600;
}

.sp-rest-link {
  text-decoration: none;
  color: #2D2E2F;
}

.sp-icon-container {
  margin-right: 1vw;
  line-height: 2vw;
}

.sp-icon {
  padding-top: 0.3vh;
}

.sp-rest-health-score {
  color:rgba(2, 122, 151, 1);
  font-weight: 600;
}

.sp-rest-info-box span {
  vertical-align: middle;
}

.sp-rest-reviews-container {
  position: absolute;
  top: 58vw;
}

.share-btn {
  width: 12vw;
  height: 3vw;
  background-color: transparent;
  position: absolute;
  left: 20vw;
  top: 31vw;
  border-radius: 4px;
  border: 1px solid rgba(200, 201, 202, 1);
  color: #2D2E2F;
  font-size: 1.3vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s;
}

.share-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.toast-icon {
  color: rgba(0, 128, 85, 1);
}

.sp-rest-reviews-container {
  width: 94%;
  padding-bottom: 1em;
  color: #2D2E2F;
}

.reviews-linebreak {
  border-top: 1px solid rgb(200, 201, 202);
  margin-left: 6vw;
  margin-bottom: 1em;
}

.empty-reviews-container {
  font-size: 1.2vw;
  padding-left: 6vw;
}

.reviews-container {
  padding-left: 6vw;
}

.sp-review {
  margin-bottom: 3.5vw;
  width: 60%;
}

.reviewer-name {
  font-weight: bold;
  font-size: 1.2vw;
  word-spacing: 0.1em;
  cursor: pointer;
}

.reviewer-name:hover {
  text-decoration: underline #2D2E2F;
}

.reviewer-profile {
  margin-right: 0.5vw;
}

.reviewer-profile, .reviewer-name {
  display: inline-block;
}

.sp-review-rating:has(.average-rating-num) {
  .average-rating-num {
    display: none;
  }
}

.sp-review-rating:has(.total-reviews) {
  .total-reviews {
    display: none;
  }
}

.sp-review-rating {
  margin-top: 0.25vw;
  font-size: 1.5vw;
}

.rating-time {
  font-size: 1.2vw;
  color: rgb(138, 141, 144);
  margin-left: 0.5vw;
}

.edited {
  font-size: 1.2vw;
  color: rgb(138, 141, 144);
  margin-left: 1.5vw;
}

.sp-review-body {
  font-size: 1.1vw;
  text-align: justify;
  line-height: 1.3em;
}

.reaction-button-group {
  margin-top: 0.6vw;
  width: 60%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.reaction-btn {
  width: fit-content;
  cursor: pointer;
}

.disabled-reaction-btn {
  cursor: not-allowed;
}

.reaction-btn-icon {
  text-align: center;
}

.reaction-btn-text {
  color: rgb(138, 141, 144);
  font-size: 0.9vw;
  font-weight: 600;
}

.already-clicked-btn-text {
  color: #3D3E3F;
}

.reaction-count {
  font-weight: 900;
}

.more-options-btn {
  position: relative;
  right: -40vw;
}

@media screen and (width <= 1440px){
  .more-options-btn {
    right: -39.5vw;
  }
}

@media screen and (width < 1300px) {
  .more-options-btn {
    right: -39.2vw;
  }
}

@media screen and (width < 1100px) {
  .more-options-btn {
    right: -38.9vw;
  }
}

@media screen and (width < 900px) {
  .more-options-btn {
    right: -38.5vw;
  }
}

@media screen and (width < 800px) {
  .more-options-btn {
    right: -38.3vw;
  }
}

@media screen and (width < 700px) {
  .more-options-btn {
    right: -37.8vw;
  }
}

@media screen and (width < 600px) {
  .more-options-btn {
    right: -37.5vw;
  }
}

@media screen and (width = 500px) {
  .more-options-btn {
    right: -38.5vw;
  }
}

.modal-content {
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #2D2E2F;
}

.modal-title {
  font-size: 1.5vw;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.modal-text {
  font-size: 1.1vw;
  line-height: 1.4em;
}

.modal-btn-gp {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
}

.modal-btn {
  display: inline-block;
  font-size: 1.1vw;
  font-weight: bold;
  padding: 0.85em;
  border-radius: 4px;
  cursor: pointer;
}

.modal-btn:first-child {
  color: #027A97;
  margin-right: 1.5em;
  background-color: #E2E2E6;
  transition: background-color 1s;
}

.modal-btn:first-child:hover {
  background-color: rgb(199, 199, 199);
}

.modal-btn:nth-child(2) {
  background-color: rgba(224, 7, 7, 1);
  color: white;
  transition: background-color 1s;
}

.modal-btn:nth-child(2):hover {
  background-color: rgb(244, 13, 21);
}
