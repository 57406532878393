.loading-container {
  background-color:white;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
}

.loading-container div {
  position: relative;
  display: flex;
  align-items: center;
}

.loading-content {
  display: inline-block;
}

.loading-title {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -9vw;
  font-size: 2vw;
  font-weight: bold;
  color: #2D2E2F;
}

.loading-animation {
  position: relative;
  left: 6vw;
}