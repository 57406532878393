.filter-bar-container1,
.filter-bar-container2,
.filter-bar-container3 {
  color: inherit;
  font-family: inherit;
}

.filter-title1,
.filter-title2,
.filter-title3 {
  margin-bottom: 2vh;
  font-weight: bold;
  font-size: 1.3vw;
  color: inherit;
}

.filter-btn-group1,
.filter-btn-group2 {
  color: inherit;
  font-size: 1vw;
  text-align: center;
  padding: 0.5vh 1.2vw;
  border: none;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 0.8s;
  cursor: pointer;
  font-family: inherit;
  letter-spacing: 0.1em;
}

.filter-btn-group1:hover,
.filter-btn-group2:hover {
  background-color: rgb(226, 226, 230);
}

.filter1 #filter-btn-1 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid rgba(200, 201, 202, 1);
}

.filter1 #filter-btn-2 {
  border-right: 1px solid rgba(200, 201, 202, 1);
}

.filter1 #filter-btn-2,
.filter1 #filter-btn-3 {
  border-bottom: 1px solid rgba(200, 201, 202, 1);
  border-top: 1px solid rgba(200, 201, 202, 1);
}

.filter1 #filter-btn-4 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid rgba(200, 201, 202, 1);
}

.filter-bar-container2,
.filter-bar-container3 {
  margin-top: 4vh;
}

@media screen and (width <= 1440px) {
  .filter-bar-container2,
  .filter-bar-container3 {
    margin-top: 6vh;
  }
}

.filter-btn-group2 {
  border: 1px solid rgba(200, 201, 202, 1);
  width: 6vw;
}

.average-rating-filter {
  cursor: pointer;
  margin: 1.5vh 0px;
  font-size: 1.8vw;
  text-align: center;
  border-radius: 4px;
}

.average-rating-filter {
  color: rgb(226, 226, 230);
  transition-property: color;
  transition-duration: 0.8s;
  width: 11vw;
}

.average-rating-filter:hover {
  color: #2D2E2F;
}

.activeBtn {
  background-color: rgb(226, 226, 230);
}