.see-you-soon-container {
  margin-top: 9vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vw;
  width: 58%;
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.see-you-soon-content {
  display: grid;
  grid-template-columns: 50% 50%;
}

.see-you-soon-title {
  font-weight: bold;
  font-size: 2vw;
  color: #2D2E2F;
}

.see-you-soon-title div:first-child {
  margin-bottom: 0.4vw;
}

.see-you-soon-title div:last-child {
  margin-bottom: 1.5vw;
}

.see-you-soon-body p {
  text-align: justify;
  color: #2D2E2F;
  font-size: 1.1vw;
  line-height: 1.3em;
}

.contact-btns {
  margin-top: 1.2vw;
  display: flex;
  justify-content: space-between;
}

.hp-share-btn,
.portfolio-btn,
.resume-btn {
  width: 7.5vw;
  height: 2vw;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgb(200, 201, 202);
  color: #2D2E2F;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s;
  text-decoration: none;
}

.hp-share-btn:hover,
.portfolio-btn:hover,
.resume-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.developer-tel-email-container {
  margin-top: 1.5vw;
  outline: none;
}

.developer-tel-email {
  width: 75%;
  color: #333;
  font-size: 0.9vw;
  line-height: 1.5vw;
}

.developer-tel-container,
.developer-email-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.developer-tel-container {
  margin-bottom: 0.35vw;
}

.developer-tel,
.developer-email {
  color: #777;
  font-size: 1.1vw;
  line-height: 1.5vw;
}

.available-time-container {
  margin-top: 0.6em;
  font-size: 0.9vw;
  color: #777;
}

@media screen and (width > 1440px) {
  .available-time-container {
    margin-top: 1em;
  }
}

.see-you-soon-picture {
  width: 100%;
}

.see-you-soon-picture img {
  width: inherit;
}
