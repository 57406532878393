.hp #search-bar,
.non-hp #search-bar {
  width: 29vw;
  height: 2.5vw;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 1vw;
  padding: 0px 0.5vw;
  font-family: "Open Sans", Helvetica, sans-serif;
  position: absolute;
  left: 30vw;
  top: 1.5vw;
}

.hp #search-bar {
  border: none;
}

.hp #search-bar:focus {
  outline: none;
  box-shadow: none;
}

.hp #search-bar-btn,
.non-hp #search-bar-btn {
  background-color: rgba(224, 7, 7, 1);
  width: 2.5vw;
  height: 2.5vw;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1.2vw;
  cursor: pointer;
  position: absolute;
  top: 1.5vw;
  left: 60vw;
  text-align: center;
}

.fa-search {
  position: relative;
  top: 25%;
}

.non-hp #search-bar {
  border: 1px solid #ebebeb;
  width: 30vw;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.non-hp #search-bar:focus {
  outline: none;
}

.non-hp #search-bar-btn {
  color: white;
  box-shadow: 0px 6px 8px 1px rgba(0, 0, 0, 0.27);
  height: 2.48vw;
}

#search-bar-content form {
  display: inline;
}

.search-dropdown-menu {
  position: absolute;
  top: 4vw;
  left: 30vw;
  font-size: 1vw;
  z-index: 10;
}

.hp .search-dropdown-menu {
  background-color: rgb(119, 119, 119);
}

.non-hp .search-dropdown-menu {
  background-color: rgb(211, 211, 211);
}

.dropdown-list {
  width: 30vw;
}

.dropdown-item {
  line-height: 1.5em;
  padding-left: 0.5em;
}

.hp .dropdown-item {
  border-bottom: 1px solid lightgray;
}

.non-hp .dropdown-item {
  border-bottom: 1px solid gray;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.hp .dropdown-item:hover {
  background-color: rgb(168, 168, 168);
  cursor: pointer;
}

.non-hp .dropdown-item:hover {
  background-color: rgb(160, 160, 160);
  cursor: pointer;
}