#nav-bar {
  font-family: Helvetica, sans-serif;
  position: relative;
  z-index: 10;
}

.hp #nav-bar-content {
  margin: 0px 2vw;
  padding-top: 1.5vw;
  color: white;
}

.hp .navlink {
  color: white;
} 

.hp .title-nav-link {
  text-decoration: none;
  color: white;
}

#app-title {
  font-size: 2vw;
  font-weight: 600;
  display: inline-block;
}

#app-logo {
  width: 2vw;
}

.hp #category-btn, .non-hp #category-btn {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1vw;
  margin-left: 10vw;
  margin-right: 5vw;
  padding: 0px 0.6vw;
  height: 2.5vw;
  position: absolute;
  top: 1.5vw;
  left: 8vw;
  outline: none;
  font-family: inherit;
}

.hp #category-btn {
  background-color: transparent;
  border: 1px solid rgba(200, 201, 202, 1);
  color: white;
}

#category-btn span{
  margin-right: 0.6vw;
}

.contact-info-btn{
  display: inline-block;
  font-size: 2vw;
  margin-left: 10vw;
}

.contact-info-btn a {
  color: inherit;
}

.hp .contact-info-btn span {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.70);
}

.hp .contact-info-btn span:hover {
  color: rgba(255, 255, 255, 1);
}

.fa-linkedin-square {
  position: absolute;
  top: 1.65vw;
  right: 26vw;
}

.fa-github {
  position: absolute;
  top: 1.65vw;
  right: 23vw;
}

.nav-bar-btn {
  display: inline-block;
  font-size: 1vw;
  border: 1px solid gray;
}

.auth-btn {
  padding: 1vh;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  width: 5.3vw;
  box-sizing: border-box;
  text-align: center;
  transition-property: background-color;
  transition-duration: 0.8s;
}

.hp #demo-btn {
  position: absolute;
  right: 14vw;
  border: 2px solid rgba(200, 201, 202, 1);
}

.hp #login-btn {
  position: absolute;
  right: 8vw;
  border: 2px solid rgba(200, 201, 202, 1);
}

.hp #signup-btn {
  background-color: rgba(224, 7, 7, 1);
  position: absolute;
  right: 2vw;
  border: 2px solid transparent;
}

.red-btn:hover {
  filter: brightness(1.1);
}

.hp .gray-btn:hover, .hp #category-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.non-hp #nav-bar-content {
  margin: 0px 2vw;
  padding-top: 1.5vw;
  color: #2D2E2F;
}

.non-hp .navlink {
  color: black;
}

.non-hp .title-nav-link {
  text-decoration: none;
  color: black;
}

.non-hp-logo {
  filter: brightness(1.3);
}

.non-hp #category-btn {
  background-color: rgba(0, 0, 0, 0.12);
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: #2D2E2F;
}

.non-hp .contact-info-btn span {
  cursor: pointer;
  color: #999;
}

.non-hp .contact-info-btn span:hover {
  color: #555;
}

.non-hp #demo-btn {
  position: absolute;
  right: 14vw;
  border: 2px solid rgba(200, 201, 202, 1);
  color: #2D2E2F;
}

.non-hp #login-btn {
  position: absolute;
  right: 8vw;
  border: 2px solid rgba(200, 201, 202, 1);
  color: #2D2E2F;
}

.non-hp #signup-btn {
  background-color: rgba(224, 7, 7, 1);
  position: absolute;
  right: 2vw;
  border: 2px solid transparent;
  color: white;
}

.non-hp .gray-btn:hover, .non-hp #category-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}