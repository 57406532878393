.login-page {
  font-family: Helvetica, sans-serif;
  position: relative;
}

.signup-link {
  text-decoration: none;
  color: #0073bb;
}

.signup-link:hover {
  text-decoration: underline;
}

#signup-link-1 {
  font-weight: bold;
  font-size: 1vw;
  margin-top: 1.5vh;
  margin-bottom: 3vh;
}

#login-form-container {
  position: absolute;
  top: 28vh;
  left: 23vw;
  text-align: center;
  width: 20vw;
}

.login-form-lb {
  top: 9.5vw;
}

.login-input {
  height: 3vh;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 0.5vh 0.5vw;
  font-size: 1vw;
  color: #333;
}

.login-input:focus {
  outline: none;
  border: 1px solid #0073bb;
  box-shadow: 0 0 4px rgba(0, 115, 187, 0.3);
}

#login-user-email {
  position: relative;
  top: 4vh;
  right: 2vw;
}

#login-email {
  width: 22vw;
}

#login-user-password {
  position: relative;
  top: 6vh;
  right: 2vw;
}

#login-password {
  width: 22vw;
}

#submit-login-btn {
  position: absolute;
  top: 37vh;
  font-size: 1.2vw;
  left: -2vw;
  width: 23.1vw;
  padding: 1vh 0px;
  border: none;
  background-color: #d32323;
  color: white;
  font-weight: bold;
  border-radius: 4px;
}

#submit-login-btn:hover {
  background-color: #dc4f4f;
  cursor: pointer;
}

#signup-link-2 {
  position: absolute;
  top: 43vh;
  right: -1vw;
  font-size: 0.9vw;
}

#signup-link-2 span {
  color: #999;
}