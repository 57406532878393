.review-pg-container {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 5vw;
}

.write-review-container {
  width: 70%;
  margin: 0 auto;
}

.rp-restaurant-name {
  font-size: 2.1vw;
  font-weight: 500;
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 1em;
}

.review-container {
  border: 1px solid rgba(200, 201, 202, 1);
  border-radius: 5px;
  height: 50vh;
  padding: 1em;
}

.curr-rating,
.modal-rating-container {
  display: flex;
  align-items: center;
}

.rating-labels-container,
.modal-rating-text {
  font-size: 1.2vw;
  margin-left: 0.5em;
}

.review-reminder-text,
.modal-reminder-text {
  margin-top: 0.7em;
  padding-left: 0.3em;
  color: rgb(110, 112, 114);
  font-size: 1.2vw;
}

.modal-reminder-text {
  margin-top: 0.5em;
}

.review-reminder-category-container,
.modal-reminder-categories {
  font-size: 1vw;
  font-weight: bold;
  color: rgb(110, 112, 114);
  margin-top: 1em;
  padding-left: 0.3em;
}

.modal-reminder-categories {
  padding-left: 0;
}

.review-reminder-category,
.modal-reminder-categories span {
  border: 1px solid#EBEBEB;
  border-radius: 5px;
  padding: 0.2em;
  background-color: rgb(240, 240, 240);
}

.review-reminder-category:nth-child(2),
.modal-reminder-categories span:nth-child(2) {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.review-text-area {
  margin-top: 1.6vw;
  margin-left: 0.1em;
  width: 100%;
  height: 67%;
  resize: none;
  border: none;
  font-size: 1.2vw;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  overflow-y: auto;
}

.review-errors-container {
  font-size: 1.1vw;
  margin-top: 0.5vw;
  color: rgb(224, 7, 7);
}

.review-error {
  display: flex;
  align-items: center;
  font-size: inherit;
}

.submit-review-btn {
  width: 12vw;
  height: 3vw;
  background-color: rgba(224, 7, 7, 1);
  border-radius: 4px;
  color: white;
  font-size: 1.3vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s;
  padding-right: 0.2em;
  margin-top: 2em;
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.write-review-container:has(.review-errors-container) {
  .submit-review-btn {
    margin-top: 1em;
  }
}

.submit-review-btn:hover {
  background-color: rgb(244, 13, 21);
}

.rest-recent-reviews-container {
  border-left: 1px solid rgba(200, 201, 202, 1);
  padding: 0px 1vw;
  height: 78vh;
}

.rest-recent-reviews-title {
  font-size: 1.3vw;
  font-weight: 900;
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.reviews-scrollable-container {
  overflow-y: auto;
  margin-top: 0.5vw;
  height: 74vh;
}

.curr-rest-recent-review {
  margin-bottom: 1vw;
  padding-bottom: 1vw;
  border-bottom: 1px solid rgba(200, 201, 202, 1);
}

.curr-rest-recent-review:last-child {
  border-bottom: none;
}

.reviews-unscrollable-container {
  margin-top: 0.5vw;
  font-size: 1.2vw;
}

.reviewer {
  font-size: 1.1vw;
  font-weight: bold;
  margin-left: 0.5vw;
  cursor: pointer;
  word-spacing: 0.1em;
}

.reviewer:hover {
  text-decoration: underline #2D2E2F;
}

.review-user-profile, .reviewer {
  display: inline-block;
}

.recent-review-rating {
  margin-top: 0.35vw;
}

.recent-review-rating .average-rating-icon {
  font-size: 1.3vw;
}

.recent-review-rating .rating-time {
  font-size: 1vw;
}

.recent-review-body {
  font-size: 1.2vw;
  line-height: 1.2em;
  text-align: justify;
  width: 98%;
}